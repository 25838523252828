import { Layout, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const Styled = styled(Layout.Container)`
  ${style}
`;

export const Ticket = ({ ticket }) => {
  const deliveryAddress = React.useMemo(() => {
    const address = ticket?.destination?.address;
    return `${address?.address1 || ''} ${address?.city || ''}, ${address?.countrySubDivision || ''} ${
      address?.postalCode || ''
    }`;
  }, [ticket]);

  return (
    <Styled className={cn('ticket')}>
      <div className="id-status">
        <div className="ticket-item ticket-id">
          <div className="label">
            <Localization.Translate stringId="ticket" data-testid="ticket-label" />
          </div>
          <div className="value">{ticket?.id}</div>
        </div>
        <div className="ticket-item status">
          <div className="label">
            <Localization.Translate stringId="status" data-testid="status-label" />
          </div>
          <div className="value">{ticket?.customerStatus?.statusCode}</div>
        </div>
      </div>
      <div className="ticket-item date-time">
        <div className="label">
          <Localization.Translate stringId="dispatchDateTime" data-testid="date-time-label" />
        </div>
        <div className="value">
          <Localization.ShortDateTime date={ticket?.dispatchDateTime} />
        </div>
      </div>
      <div className="ticket-item site-address">
        <div className="label">
          <Localization.Translate stringId="siteAddress" data-testid="site-address-label" />
        </div>
        <div className="value">{deliveryAddress}</div>
      </div>
    </Styled>
  );
};
