import common from '../common';
import errors from './errors';
import themes from './theme';
import features from './features';

export default {
  ...common,

  mobileTicket: 'Mobile Ticket',
  ...features,

  save: 'Spara',
  username: 'Användarnamn',
  password: 'Lösenord',
  login: 'Logga in',
  required: 'Logga in',

  light: 'Ljus',
  dark: 'Mörk',

  rememberMe: 'Kom ihåg',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Integritetspolicy',

  customer: 'Kund',
  project: 'Projekt',

  isBillable: 'Fakturerbar',
  dispatchDateTime: 'Datum',
  orderId: 'Order',
  ticketId: 'Följesedel',
  location: 'Lokation',
  vehicleTypeName: 'Fordon',
  product: 'Produkt',
  salesTax: 'moms',
  totalPrice: 'Totalbelopp',
  extendedPrice: 'utökat pris',
  destination: 'Destination',
  productId: 'Produkt ID',
  statusCode: 'Status',
  vehicleTypeId: 'Fordons ID',
  locationId: 'Lokations ID',
  customerId: 'Kund ID',
  projectId: 'Projekt ID',
  dispatchTime: 'Tid',

  showAll: 'Visa alla',
  hideAll: 'Göm alla',
  undoChanges: 'Ångra ändringar',
  resetColumns: 'Återställ',

  displayRowCount: `Visa {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,

  columnFilters: 'Filter',

  visible: 'Synlig',
  hidden: 'Gömd',

  itemDetail: 'artikeldetalj',

  quantity: 'Kvantitet',

  unloadingMethod: 'Lossningssätt',

  time: 'Tid',
  eventStatus: 'Status',

  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',

  MTQ_text: 'Cubic Meters',
  M3_text: 'Cubic Meters',
  EA_text: 'Each',
  LTR_text: 'Liters',
  YDQ_text: 'Cubic Yards',
  GLL_text: 'Gallons',
  ACR_text: 'Acres',
  HUR_text: 'Hour',
  INH_text: 'Inches',
  FOT_text: 'Foot',
  YRD_text: 'Yard',
  MMT_text: 'Millimetre',
  CMT_text: 'Centimetre',
  MTR_text: 'Metre',
  SMI_text: 'Mile (Statute Mile)',
  KMT_text: 'Kilometre',
  INK_text: 'Square Inch',
  FTK_text: 'Square Foot',
  YDK_text: 'Square Yard',
  MIK_text: 'Square Mile',
  CMK_text: 'Square Centimetre',
  MTK_text: 'Cubic Metre',
  KMK_text: 'Square Kilometre',
  INQ_text: 'Cubic Inch',
  MMQ_text: 'Cubic Millimetre',
  FTQ_text: 'Cubic Foot',
  CMQ_text: 'Cubic Centimetre',
  OZA_text: 'Fluid Ounce',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Millilitre',
  ONZ_text: 'Ounce',
  LBR_text: 'Pound',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Tonne',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton Per Square Millimetre',
  C62_text: 'One',
  NL_text: 'Load',
  MT_text: 'Mat',
  P1_text: 'Percent',
  CEL_text: 'Degree Celsius',
  FAH_text: 'Degree Fahrenheit',
  BLL_text: 'Barrel',
  CLT_text: 'Centilitre',
  DLT_text: 'Decilitre',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sack',
  '60_text': 'Percent Weight',
  BG_text: 'Bag',
  SEC_text: 'Second',
  MIN_text: 'Minute',
  '64_text': 'Pound per square inch - Gauge',
  SP_text: 'Shelf Package',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,

  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,

  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,

  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,

  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,

  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,

  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    one {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    one {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    one {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    one {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    one {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    one {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    one {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    one {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    one {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
  one {1 Percent}
  other {# Percent}
}`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
  one {1 Degree Celsius}
  other {# Degrees Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
  one {1 Degree Fahrenheit}
  other {# Degrees Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Barrels}
  one {1 Barrel}
  other {# Barrels}
}`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
  one {1 Centilitre}
  other {# Centilitres}
}`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
  one {1 Decilitre}
  other {# Decilitres}
}`,
  GLI: `{value, plural,
    =0 {0 Gallons}
  one {1 Gallon}
  other {# Gallons}
}`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
  one {1 Megagram}
  other {# Megagrams}
}`,
  SA: `{value, plural,
    =0 {0 Sacks}
  one {1 Sack}
  other {# Sacks}
}`,
  60: `{value, plural,
    =0 {0 Percent Weight}
  one {1 Percent Weight}
  other {# Percent Weight}
}`,
  BG: `{value, plural,
    =0 {0 Bags}
  one {1 Bag}
  other {# Bags}
}`,
  SEC: `{value, plural,
    =0 {0 Seconds}
  one {1 Second}
  other {# Seconds}
}`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
  one {1 Pound per square inch - Gauge}
  other {# Pounds per square inch - Gauge}
}`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
  one {1 Shelf Package}
  other {# Shelf Packages}
}`,

  mixIdLabel: 'Blandning / Beskrivning',
  orderedQtyLabel: 'Beställd mängd',
  loadedQtyLabel: 'Lastad mängd',

  addedByCustomer: 'Added By Customer',

  PRINTED: 'Skapad',
  LOADING_STARTED: 'Start Loading',
  LOADING_COMPLETE: 'Finish Loading',
  TO_JOB: 'Till arbetsplats',
  ARRIVE_JOB: 'Ankomst arbetsplats',
  UNLOADING: 'Lossad',
  END_UNLOADING: 'Slut lossning',
  LEAVE_JOB: 'Lämna arbetsplats',
  IN_YARD: 'På fabriksområde',
  PUMPING_STARTED: 'Start Pump',
  PUMPING_COMPLETE: 'Finish Pump',
  READY_TO_PUMP: 'Ready to Pump',
  FIRST_WCCONTACT: 'First Water Cement Contact',

  plantName: 'Fabrik',
  plantAddress: 'Fabrik adress',
  loadingScheduleStart: 'Planerad lastningstid',

  deliveryAddress: 'Leveransadress',
  phoneNumber: 'Telefonnummer',
  unloadingScheduleStart: 'Planerad lossningstid',
  agreedUnloading: 'Ordered Unloading Times',

  deliveryInformation: 'Leveransinformation',
  next: 'Nästa',

  material: 'Produkt',
  loadingPoint: 'Lastningsställe',
  unloadingPoint: 'Losnningsställe',

  comment: 'Kommentar',

  sapDeliveryNumber: 'SAP följesedelsnummer',
  salesOrderNumber: 'SAP ordernummer',
  wcDateTime: 'datum och tid för W/C kontakt',
  po: 'PO nummer',
  orderedByName: 'Beställare',
  orderedByPhone: 'Beställare tel.',
  resourceId: 'Fordons ID',
  totalLoadedQty: 'Totalt lastad mängd inkl detta lass',
  spacing: 'Intervall',
  deliveryFlow: 'Gjuthastighet',
  timeOnSite: 'Beställd lossningstis',
  salesText: 'Sälj text',
  strengthClass: 'Hållfasthetsklass',
  environmentExposure: 'Miljöpåverkan',
  metExposureClasses: 'Uppfyllda exponeringsklasser',
  dmax: 'Dmax',
  standardCert: 'Standard',
  workabilityTarget: 'Beställt sättmått',
  stoneQuality: 'Stenkvalitet',
  sandQuality: 'Sandkvalitet',
  targetWC: 'Target W/C ratio from recipe',
  controlClass: 'Control Class',
  chlorideClass: 'Kloridhaltsklass',
  typeAndStrength1: 'Cementtyp 1',
  typeAndStrength2: 'Cementtyp 2',
  typeOfAddition: 'Tillsatsmaterial',
  typeOfAdmixture: 'Tillsatsmedel',
  orderedTemp: 'Beställd temperatur',
  fibers1: 'Typ och innehåll fiber 1',
  fibers2: 'Typ och innehåll fiber 1',
  calculatedMaturityTime: 'Calculated maturity time from W/C contact time',

  mixIdentity: 'Mix Identity',
  grade: 'Grade',
  mixType: 'Mix Type',
  maxAggSize: 'Max Agg Size',
  maxAggType: 'Max Agg Type',
  cementType: 'Cement Type',
  admixtures: 'Admixtures',
  addmixDescription: 'Addmix Description',
  slump: 'Slump',
  minCementContent: 'Min Cement Content',
  maxWatCemRatio: 'Max Water/Cement Ratio',
  maxWaterToAdd: 'Max Water To Add',
  mortarMinWorkingLife: 'Mortar Min Working Life',
  code: 'Code',
  dcClass: 'dcClass',
  chloride: 'Chloride',
  signedByHanson: 'Signed By Hanson',
  warmStamp: 'Warm Stamp',
  coldStamp: 'Cold Stamp',
  containsSikatard: 'Contains Sikatard',
  containsDelvo: 'Contains Delvo',

  generalDelivery: 'Generell leveransinformation',
  materialInfo: 'Produktinformation',

  water: 'Vatten',
  materialService: 'Material/Service',
  returnedMaterial: 'Returbetong',

  waterAdded: 'Vatten tillsatt',
  concreteOnTruck: 'Betong kvar',
  concreteOnTruckUom: 'Default Concrete on Truck UOM',
  reason: 'Orsak',
  customerAddedWater: 'Tillsatt vatten enligt kund',
  addWater: 'Tillsätt vatten',
  addedAt: 'Added at',

  addMaterialService: 'Tillsätt material/service',
  customerAddedProduct: 'Tillsatt produkt enligt kund',

  addReturnedMaterial: 'Lägg till returnerat material',
  leftover: 'Returbetong',

  submitAcceptance: 'Accept',
  customerAcceptance: 'Kund acceptans',
  finalize: 'Finalize',

  materialSummary: 'Summering',
  mix: 'Produkt',
  ordered: 'Beställt',
  loaded: 'Lastad mängd',
  totalOrdered: 'Total Order Quantity',
  loadedOrderQuantity: 'Loaded Order Quantity',
  loadedOrder: 'Loaded Order',

  driverComment: 'Chaufförskommentar',
  customerComment: 'Kundkommentar',
  editComment: 'Redigera kommentar',
  addComment: 'Lägg till kommentar',
  additionalInfo: 'Ytterliggare information',
  onBoard: 'I bilen',
  driver: 'Chaufför',
  commentInstruction: 'Kommnetarsunstruktion',
  signatureInstruction: 'Signatur instruktion',
  customerSignature: 'Kund signatur',

  general: 'Generellt',

  status: 'Status',
  updateStatusTime: 'Uppdatera status tid',
  delete: 'Radera',
  cancel: 'Avbryt',
  back: 'Tillbaka',
  add: 'Lägg till',
  ok: 'OK',
  lastRefresh: 'Last Refresh',
  refreshEnabled: 'Enable Refresh',

  id: 'ID',
  name: 'Namn',
  invoiceable: 'Fakturerbar',
  provideSignature: 'Fakturerbar',
  ticket: 'Följesedel',
  details: 'Detaljer',
  qrCode: 'QR',

  driverAcceptanceReason: 'Orsak',
  driverAcceptance: 'Chaufförs accept',

  'reason-code': 'Orsakskod',
  'add-reason-code': 'Lägg till orsakskod',

  'qr-code': 'QR kod',
  'unloading-method': 'Lossningssätt',
  'add-unloading-method': 'Lägg till lossningssätt',

  'driver-comment': 'Chaufförs kommentar',
  'add-driver-comment': 'Lägg till chaufförs kommentar',
  'driver-sellable': 'Driver Sellable',

  vehicle: 'Vehicles',
  'add-vehicle': 'Add Vehicle',

  'vehicle-type': 'Vehicle Types',
  vehicleTypes: 'Vehicle Types',
  vehicleType: 'Vehicle Type',
  'add-vehicle-type': 'Add Vehicle Type',
  countries: 'Countries',
  country: 'Country',

  type: 'Typ',
  uom: 'Måttenhet',
  mandatory: 'Obligatorsik',

  qrTemplate: 'QR kod mall',
  includeMixInfo: 'Inkludera blandningsinformation i QR kod',
  includeTicketEvents: 'Inkludera följesedelshändelser i QR kod',

  quantityField: 'Aktivera kvantitets fält',
  concreteOnTruckField: 'Aktivera betong på bilen fält',
  timeField: 'Aktivera tidsfält',
  reasonField: 'Aktivera orsaks fält',

  driverInstructions: 'Chaufförs instruktioner',

  waitingForTicket: 'Waiting for ticket...',

  exceedsLoadedQuantity: 'Värde kan inte överskrida lastad kvantitet {loadedQuantity}',
  maxQuantity: 'Maximal kvantitet',
  maxQuantityError: 'Quantity cannot exceed defined maximum quantity of {maxQty}',
  maxValueError: 'Value cannot exceed defined maximum of {maxQty}',
  minValueError: 'Value cannot be less than defined minimum of {minQty}',

  notAuthorized: 'Not Authorized',

  enterTruckNumber: 'Enter Truck Number',
  setTruckNumber: 'Set Truck Number',

  commentList: 'Comments List',
  list: 'List',

  gridPreferences: 'Grid Preferences',

  loadingUnloadingPoints: 'Loading/Unloading Points',

  activity: 'Activity',

  crn: 'CRN',
  update: 'Update',

  CONCRETE: 'Concrete',
  WATER: 'Water',
  ADDITIVE: 'Additive',

  'submit-signature-button': 'Accept',
  'cancel-signature-button': 'Cancel',

  uomCode: 'Default UOM',
  'product-type': 'Product Types',
  'add-product-type': 'Add Product Type',
  'add-product': 'Add Product',
  'setup_driver-comment': 'Driver Comments',
  setup_product: 'Products',
  'setup_product-type': 'Product Types',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Reason Codes',
  'setup_unloading-method': 'Unloading Methods',
  'setup_entity-mt': 'Company',
  setup_vehicle: 'Vehicles',
  'setup_vehicle-type': 'Vehicle Types',
  'setup_data-retentions': 'Data Retention',
  'add-data-retentions': 'Add Data Retention',
  'data-retentions': 'Data Retention',
  setup_carriers: 'Carriers',
  'add-carriers': 'Add Carriers',
  carriers: 'Carriers',
  'setup_concrete-classes': 'Material Compliance',
  'add-concrete-classes': 'Add Material Compliance',
  'concrete-classes': 'Material Compliance',
  'setup_forms-and-waivers': 'Forms & Waivers',
  'add-forms-and-waivers': 'Add Forms & Waivers',
  'forms-and-waivers': 'Forms & Waivers',

  companyFleet: 'Company Fleet',

  category: 'Category',
  interval: 'Interval',
  orders: 'Orders And Tickets',
  '30days': '30 Days',
  '60days': '60 Days',
  '90days': '90 Days',
  '1year': '1 Year',
  '3years': '3 Years',

  waterMandatory: 'Water Mandatory',

  qrCodeTemplate: 'QR Code Template',

  waterConfig: 'Optional Fields',

  waterProduct: 'Water',
  waterProductType: 'Water Product Type',
  defaultWaterProduct: 'Default Water Product',

  invalidTemplate: 'Invalid Template',

  siteAddress: 'Site Address',
  siteContactName: 'Site Contact Name',
  siteContactPhoneNumber: 'Site Contact Phone Number',

  'clear-signature-button': 'Clear Signature',

  customerCommentEnabled: 'Enable Customer Comment',
  driverCommentEnabled: 'Enable Driver Comment',

  driverCommentMaxCharacters: 'Max Length',
  customerCommentMaxCharacters: 'Max Length',

  timeZone: 'Time Zone',

  driverDidNotAdd: 'Driver Did Not Add',
  maxLineItems: 'Max Number of Line Items',
  oneLineItem: 'Must have at least 1 line item',

  signatureSiteContact: 'Customer Name',
  ticketEvents: 'Ticket Events',
  hideEvent: 'Hide Event',
  editable: 'Editable',

  ticketDetail: `Ticket #{value}`,
  statusTimes: 'Status Times',

  materialAndDeliveryInfo: 'Material and Delivery Information',

  customerRejection: 'Customer Rejection',
  'submit-rejection-button': 'Submit Rejection',
  accept: 'Accept',
  reject: 'Reject',

  signature: 'Signature',
  'no-signature': 'No Signature',

  unFinalizePassword: 'Un-Finalize Password',

  waterInWorkflow: 'Enable Water Step in Workflow',
  multicountry: 'Multicountry',

  timestampAfterSequence: 'WARNING: Time should not be before previous timestamp',
  timestampBeforeSequence: 'WARNING: Time should not be after next timestamp',

  noRestQuantity: 'No Rest Quantity',

  returnedMaterialMandatory: 'Must Interact With Returned Material Step',

  driverCommentMaxChars: 'Length cannot exceed max of {maxLength} characters.',

  QrCodeEnabled: 'Enable QR Code',

  returnedMaterialInWorkflow: 'Enable Returned Material Step in Workflow',

  materialServiceInWorkflow: 'Enable Material/Service Step in Workflow',

  totalPumpedQuantity: 'Total Pumped Quantity',

  concrete: 'Concrete',

  materialComplianceEnabled: 'Enable Material Compliance',

  pumpStatusTimes: 'Pump Status Times',

  pollingInterval: 'Polling Interval (seconds)',

  tenSecs: 'Interval must be at least 10 seconds',

  materialCompliance: 'Material Compliance',
  consistenceValue: 'Consistence',
  consistenceIsCompliant: 'Consistence complies with BS8500-2',
  airContentValue: 'Air content',
  airContentIsCompliant: 'Air Content complies with BS8500-2',

  consistenceIsCompliantShort: 'Consistence complies',
  airContentIsCompliantShort: 'Air Content complies',
  compliance: 'Material Compliance with BS8500-2',

  family_name: 'Last Name',
  given_name: 'First Name',
  email: 'Email',
  role: 'Role',
  inviteUser: 'Invite User',
  carrier: 'Carrier',
  emailOrPhoneNumber: 'Email',
  usersEditor: 'User Editor',
  verifyPassword: 'Verify Password',
  verificationCode: 'Verification Code',
  register: 'Register',
  registerInstructions: 'Please fill out this form to finalize your registration.',
  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',
  passwordMinCharacters: 'Password must contain 8 or more characters.',
  passwordMaxCharacters: 'Password cannot contain more than 99 characters.',
  passwordLowerCase: 'Password must include 1 or more lower case letter.',
  passwordUpperCase: 'Password must include 1 or more upper case letter.',
  passwordNumber: 'Password must include 1 or more numeric character.',
  passwordSpecialCharacter: 'Password must include 1 or more special character.',
  passwordsDontMatch: "Passwords don't match!",
  success: 'Success!',
  'registration-success': 'Your registration was successful.',
  'post-registration-instructions': "You've completed the registration process. You may now log in.",
  createDate: 'Create Date',
  customerPo: 'Customer PO Number',
  specification: 'Specification',

  description: 'Description',
  classType: 'Class Type',
  minimumValue: 'Minimum Value',
  maximumValue: 'Maximum Value',

  TrackIt: 'TrackIt',
  trackIt3PContext: 'TrackIt 3P Context',
  inviteLanguage: 'Invite Language',
  truckNumber: 'Truck Number',
  adverseTemperature:
    'Ambient temperature at the time of mixing was such that we cannot guarantee that the concrete will be in the temperature ranges of 5-35oC as specified in EN 206.',
  environmentalNote:
    'This load contains an environmental agent which does not exceed 0.03% by weight of the concrete. Water containing this material for the manufacture of concrete will comply with BS EN 1008.',

  unloadingMethodEnabled: 'Enable Unloading Method',

  content: 'Content',
  WARNINGS: 'Warnings',
  TERMS: 'Terms',
  DAMAGE: 'Damage',
  ADDITIONALWAIVER: 'Additional Waiver',

  warrantyVoided: 'Material Warranty Voided',
  materialWarrantyVoidedEnabled:
    'When Water and/or Additional Materials are added show a Certification Loss Note at time of signature.',

  'setup_templates-mt': 'Mallar',
  copyFrom: 'Kopiera från...',

  errors,
  themes,
};
