import cn from 'classnames';
import { Core, Drawer, Form, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import {
  useCompanySetup,
  useCreateSetupItem,
  useFormsAndWaiversSetup,
  useUpdateSetupItem,
} from '../../../MasterDataProvider';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const types = [
  { id: 'WARNINGS' },
  { id: 'TERMS' },
  { id: 'DAMAGE' },
  { id: 'WATER' },
  { id: 'ADDITIONALWAIVER' },
];

export const FormsAndWaiversEditor = () => {
  const { Components, values } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { translateObjects } = Localization.useTranslateObjects();

  const typeOptions = React.useMemo(() => {
    return translateObjects(types, {
      getPath: 'id',
      setPath: 'label',
    });
  }, [translateObjects]);

  const formsAndWaiversSetup = useFormsAndWaiversSetup();

  const busy = React.useMemo(() => {
    return formsAndWaiversSetup.isLoading;
  }, [formsAndWaiversSetup.isLoading]);

  const create = useCreateSetupItem();
  const update = useUpdateSetupItem();
  const companySetup = useCompanySetup();

  const saveMasterData = React.useCallback(
    item => {
      return create(item);
    },
    [create]
  );

  const editMasterData = React.useCallback(
    item => {
      return update(item);
    },
    [update]
  );

  const handleSave = React.useCallback(() => {
    return saveMasterData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, saveMasterData, values]);

  const handleEdit = React.useCallback(() => {
    return editMasterData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, editMasterData, values]);

  return (
    <Core.Spinner spin={busy}>
      <Styled className={cn('forms-and-waivers-editor')}>
        <Components.VehicleTypes />
        <Components.Type options={typeOptions} />
        <Components.Content />
        {companySetup?.data?.isMulticountry && <Components.Countries />}
        <Components.Status />
        <div className="actions">
          <div>
            <Components.CancelButton onCancel={closeDrawer} enabled />
            <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
          </div>
        </div>
      </Styled>
    </Core.Spinner>
  );
};
