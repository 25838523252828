import cn from 'classnames';
import { Core } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useListTickets } from '../../../../../../query-hooks/tickets';
import { Ticket } from './Ticket';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const TicketsList = () => {
  const ticketsQuery = useListTickets();

  return (
    <Styled className={cn('ticket-list')}>
      <Core.Spinner spin={ticketsQuery?.isLoading}>
        {ticketsQuery?.data?.map?.(ticket => (
          <Ticket ticket={ticket} />
        ))}
      </Core.Spinner>
    </Styled>
  );
};
