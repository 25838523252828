import common from '../common';
import errors from './errors';
import themes from './theme';
import features from '../en-US/features';

export default {
  ...common,

  mobileTicket: 'Online Leveringsbon',
  ...features,

  save: 'Opslaan',

  username: 'Gebruikersnaam',
  password: 'Wachtwoord',
  login: 'Aanmelden',
  required: 'Verplicht',

  light: 'Licht',
  dark: 'Donker',

  rememberMe: 'Onthoud me',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Privacybeleid',

  customer: 'Klant',
  project: 'Project',

  isBillable: 'Factureerbaar',
  dispatchDateTime: 'Datum',
  orderId: 'Bestelling',
  ticketId: 'Levering',
  location: 'Locatie',
  vehicleTypeName: 'Wagen',
  product: 'Product',
  salesTax: 'Belasting',
  totalPrice: 'Totaal',
  destination: 'Bestemming',
  productId: 'Product ID',
  statusCode: 'Status',
  vehicleTypeId: 'Wagen Type ID',
  locationId: 'Locatie ID',
  customerId: 'Klant ID',
  projectId: 'Project ID',
  dispatchTime: 'Tijd',

  showAll: 'Toon alles',
  hideAll: 'Verberg alles',
  undoChanges: 'Wijzigingen ongedaan maken',
  resetColumns: 'Kolommen terugzetten',

  displayRowCount: `Weergave {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,

  columnFilters: 'Kolomfilters',

  visible: 'Zichtbaar',
  hidden: 'Verborgen',

  itemDetail: 'Item details',

  quantity: 'Hoeveelheid',

  unloadingMethod: 'Losmethode',

  time: 'Tijd',
  eventStatus: 'Status',

  MTQ_text: 'Cubic Meters',
  M3_text: 'Cubic Meters',
  EA_text: 'Each',
  LTR_text: 'Liters',
  YDQ_text: 'Cubic Yards',
  GLL_text: 'Gallons',
  ACR_text: 'Acres',
  HUR_text: 'Hour',
  INH_text: 'Inches',
  FOT_text: 'Foot',
  YRD_text: 'Yard',
  MMT_text: 'Millimetre',
  CMT_text: 'Centimetre',
  MTR_text: 'Metre',
  SMI_text: 'Mile (Statute Mile)',
  KMT_text: 'Kilometre',
  INK_text: 'Square Inch',
  FTK_text: 'Square Foot',
  YDK_text: 'Square Yard',
  MIK_text: 'Square Mile',
  CMK_text: 'Square Centimetre',
  MTK_text: 'Cubic Metre',
  KMK_text: 'Square Kilometre',
  INQ_text: 'Cubic Inch',
  MMQ_text: 'Cubic Millimetre',
  FTQ_text: 'Cubic Foot',
  CMQ_text: 'Cubic Centimetre',
  OZA_text: 'Fluid Ounce',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Millilitre',
  ONZ_text: 'Ounce',
  LBR_text: 'Pound',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Tonne',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton Per Square Millimetre',
  C62_text: 'One',
  NL_text: 'Load',
  MT_text: 'Mat',
  P1_text: 'Percent',
  CEL_text: 'Degree Celsius',
  FAH_text: 'Degree Fahrenheit',
  BLL_text: 'Barrel',
  CLT_text: 'Centilitre',
  DLT_text: 'Decilitre',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sack',
  '60_text': 'Percent Weight',
  BG_text: 'Bag',
  SEC_text: 'Second',
  MIN_text: 'Minute',
  '64_text': 'Pound per square inch - Gauge',
  SP_text: 'Shelf Package',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,

  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,

  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,

  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,

  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,

  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,

  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    one {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    one {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    one {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    one {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    one {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    one {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    one {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    one {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    one {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
  one {1 Percent}
  other {# Percent}
}`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
  one {1 Degree Celsius}
  other {# Degrees Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
  one {1 Degree Fahrenheit}
  other {# Degrees Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Barrels}
  one {1 Barrel}
  other {# Barrels}
}`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
  one {1 Centilitre}
  other {# Centilitres}
}`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
  one {1 Decilitre}
  other {# Decilitres}
}`,
  GLI: `{value, plural,
    =0 {0 Gallons}
  one {1 Gallon}
  other {# Gallons}
}`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
  one {1 Megagram}
  other {# Megagrams}
}`,
  SA: `{value, plural,
    =0 {0 Sacks}
  one {1 Sack}
  other {# Sacks}
}`,
  60: `{value, plural,
    =0 {0 Percent Weight}
  one {1 Percent Weight}
  other {# Percent Weight}
}`,
  BG: `{value, plural,
    =0 {0 Bags}
  one {1 Bag}
  other {# Bags}
}`,
  SEC: `{value, plural,
    =0 {0 Seconds}
  one {1 Second}
  other {# Seconds}
}`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
  one {1 Pound per square inch - Gauge}
  other {# Pounds per square inch - Gauge}
}`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
  one {1 Shelf Package}
  other {# Shelf Packages}
}`,

  mixIdLabel: 'Mengsel ID/Omschrijving',
  orderedQtyLabel: 'Bestelde hoeveelheid',
  loadedQtyLabel: 'Geladen Hoeveelheid',

  addedByCustomer: 'Toegevoegd op vraag van de klant',

  PRINTED: 'Toegewezen',
  LOADING_STARTED: 'Start laden',
  LOADING_COMPLETE: 'Einde laden',
  TO_JOB: 'Centrale verlaten',
  ARRIVE_JOB: 'Aankomst op bouwplaats',
  UNLOADING: 'Start lossen',
  END_UNLOADING: 'Einde Lossen',
  LEAVE_JOB: 'Bouwplaats verlaten',
  IN_YARD: 'Op centrale',
  PUMPING_STARTED: 'Start pompen',
  PUMPING_COMPLETE: 'Einde pompen',
  READY_TO_PUMP: 'Klaar om te pompen',
  FIRST_WCCONTACT: 'Water cement contact',

  plantName: 'Naam fabriek',
  plantAddress: 'Adres fabriek',
  loadingScheduleStart: 'Geplande laadtijd',

  deliveryAddress: 'Leveringsadres',
  phoneNumber: 'Telefoonnummer',
  unloadingScheduleStart: 'Geplande lostijdstip',
  agreedUnloading: 'Gevraagde lostijdstip',

  deliveryInformation: 'Informatie over de levering',
  next: 'Volgende',

  material: 'Materiaal',
  loadingPoint: 'Laadpunt',
  unloadingPoint: 'Lospunt',

  comment: 'Commentaar',

  sapDeliveryNumber: 'SAP leveringsnummer (BCC-nummer)',
  salesOrderNumber: 'Verkoopordernummer en artikelregel',
  wcDateTime: 'Datum en tijd W/C contact',
  po: 'PO Nummer',
  orderedByName: 'Bestelling geplaatst door naam',
  orderedByPhone: 'Bestelling geplaatst door telefoonnummer',
  resourceId: 'Wagen ID',
  totalLoadedQty: 'Totaal geladen hoeveelheid inclusief deze lading',
  spacing: 'Laad interval',
  deliveryFlow: 'Leveringstempo',
  timeOnSite: 'Totale tijd ter plaatse/geplande lostijd',
  salesText: 'Verkooptekst (materiaal-ID)',
  strengthClass: 'Sterkteklasse',
  environmentExposure: 'Milieublootstelling',
  metExposureClasses: 'Blootstellingsklasse bereikt',
  dmax: 'Dmax',
  standardCert: 'Norm (certificering)',
  workabilityTarget: 'Doelstelling verwerkbaarheid klant',
  stoneQuality: 'Steen kwaliteit',
  sandQuality: 'Zand kwaliteit',
  targetWC: 'Gewenste W/C-verhouding uit recept',
  controlClass: 'Controle klasse',
  chlorideClass: 'Controle klasse',
  typeAndStrength1: 'Type cement en sterkteklasse 1',
  typeAndStrength2: 'Type cement en sterkteklasse 2',
  typeOfAddition: 'Type toevoeging',
  typeOfAdmixture: 'Type toevoeging',
  orderedTemp: 'Geordende temperatuur',
  fibers1: 'Type en inhoud van de vezels 1',
  fibers2: 'Type en inhoud van de vezels 2',
  calculatedMaturityTime: 'Berekende rijpingstijd uit W/C-contacttijd',

  mixIdentity: 'Mix Identity',
  grade: 'Grade',
  mixType: 'Mix Type',
  maxAggSize: 'Max Agg Size',
  maxAggType: 'Max Agg Type',
  cementType: 'Cement Type',
  admixtures: 'Admixtures',
  addmixDescription: 'Addmix Description',
  slump: 'Slump',
  minCementContent: 'Min Cement Content',
  maxWatCemRatio: 'Max Water/Cement Ratio',
  maxWaterToAdd: 'Max Water To Add',
  mortarMinWorkingLife: 'Mortar Min Working Life',
  code: 'Code',
  dcClass: 'dcClass',
  chloride: 'Chloride',
  signedByHanson: 'Signed By Hanson',
  warmStamp: 'Warm Stamp',
  coldStamp: 'Cold Stamp',
  containsSikatard: 'Contains Sikatard',
  containsDelvo: 'Contains Delvo',

  generalDelivery: 'Algemene leveringsinformatie',
  materialInfo: 'Materiaal informatie',

  water: 'Water',
  materialService: 'Materiaal / Service',
  returnedMaterial: 'Geretourneerd materiaal',

  waterAdded: 'Water toegevoegd',
  concreteOnTruck: 'Beton in de truck',
  concreteOnTruckUom: 'Default beton in de truck UOM',
  reason: 'Reden',
  customerAddedWater: 'Klant heeft water toegevoegd',
  addWater: 'Water toegevoegd',
  addedAt: 'Toegevoegd om',

  addMaterialService: 'Materiaal/dienst toevoegen',
  customerAddedProduct: 'Klant heeft product toegevoegd',

  addReturnedMaterial: 'Geretourneerd materiaal toevoegen',
  leftover: 'Overgebleven',

  submitAcceptance: 'Indienen Aanvaarding',
  customerAcceptance: 'Aanvaarding door klant',
  finalize: 'Afronden',

  materialSummary: 'Samenvatting materiaal',
  mix: 'Meng',
  ordered: 'Besteld',
  loaded: 'Geladen hoeveelheid',
  totalOrdered: 'Geladen hoeveelheid',
  loadedOrderQuantity: 'Totale geladen hoeveelheid',
  loadedOrder: 'Reeds geladen',

  driverComment: 'Commentaar chauffeur',
  customerComment: 'Commentaar van de klant',
  editComment: 'Bewerk commentaar',
  addComment: 'Commentaar toevoegen',
  additionalInfo: 'Extra Info',
  onBoard: 'Aan boord',
  driver: 'Bestuurder',
  commentInstruction: 'Commentaar Instructie',
  signatureInstruction: 'Handtekening klant',
  customerSignature: 'Handtekening klant',

  general: 'Algemeen',

  status: 'Status',
  updateStatusTime: 'Status bijwerken Tijd',
  delete: 'Verwijderen',
  cancel: 'Annuleren',
  back: 'Terug',
  add: 'Toevoegen',
  ok: 'OK',
  lastRefresh: 'Laatst verniewd',
  refreshEnabled: 'Vernieuwen inschakelen',

  id: 'ID',
  name: 'Naam',
  invoiceable: 'Factureerbaar',
  provideSignature: 'Verstrek uw handtekening',
  ticket: 'Leveringsbon',
  details: 'Details',
  qrCode: 'QR-code',

  driverAcceptanceReason: 'Reden',
  driverAcceptance: 'Acceptatie bestuurder',

  'payment-method': 'Betaalmethoden',
  'add-payment-method': 'Betaalmethode toevoegen',

  'reason-code': 'Code Reden',
  'add-reason-code': 'Code Reden toevoegen',

  'qr-code': 'QR-code',
  'unloading-method': 'Losmethoden',
  'add-unloading-method': 'Voeg een losmethode toe',

  'driver-comment': 'Opmerkingen chauffeur',
  'add-driver-comment': 'Voeg een Opmerking chauffeur toe',
  'driver-sellable': 'Chauffeur verkoopbaar',

  vehicle: 'Voertuigen',
  'add-vehicle': 'Voertuig toevoegen',

  'vehicle-type': 'Voertuigtypes',
  vehicleTypes: 'Voertuigtypes',
  vehicleType: 'Voertuigtype',
  'add-vehicle-type': 'Voertuigtype toevoegen',
  countries: 'Landen',
  country: 'Land',

  type: 'Type',
  uom: 'UOM',
  mandatory: 'Verplicht',

  qrTemplate: 'QR Code Sjabloon',
  includeMixInfo: 'Mix Info opnemen in QR Code',
  includeTicketEvents: 'Leveringsgebeurtenissen opnemen in QR Code',

  quantityField: 'Hoeveelheid veld inschakelen',
  concreteOnTruckField: 'Enable Concrete On Truck Field',
  timeField: 'Tijd veld inschakelen',
  reasonField: 'Reden veld inschakelen',

  driverInstructions: 'Bestuurdersinstructies',

  waitingForTicket: 'Waiting for ticket...',

  exceedsLoadedQuantity: 'Waarde kan de geladen hoeveelheid van {loadedQuantity} niet overschrijden.',
  maxQuantity: 'Maximumhoeveelheid',
  maxQuantityError: 'De hoeveelheid kan de gedefinieerde maximumhoeveelheid van {maxQty} niet overschrijden',
  maxValueError: 'Value cannot exceed defined maximum of {maxQty}',
  minValueError: 'Value cannot be less than defined minimum of {minQty}',

  notAuthorized: 'Niet geautoriseerd',

  enterTruckNumber: 'Vrachtwagennummer invoeren',
  setTruckNumber: 'Vrachtwagennummer instellen',

  commentList: 'Commentaarlijst',
  list: 'Lijst',

  gridPreferences: 'Raster Voorkeuren',

  loadingUnloadingPoints: 'Laad-/losplaatsen',

  activity: 'Activiteit',

  crn: 'CRN',
  update: 'Update',

  CONCRETE: 'Concrete',
  WATER: 'Water',
  ADDITIVE: 'Additive',

  'submit-signature-button': 'Accepteren',
  'cancel-signature-button': 'Annuleren',

  uomCode: 'Standaard UOM',
  'product-type': 'Producttypes',
  'add-product-type': 'Producttype toevoegen',
  'add-product': 'Product toevoegen',
  'setup_driver-comment': 'Opmerkingen van de chauffeur',
  setup_product: 'Producten',
  'setup_product-type': 'Producttypes',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Code reden',
  'setup_unloading-method': 'Losmethoden',
  'setup_entity-mt': 'Bedrijf',
  setup_vehicle: 'Voertuigen',
  'setup_vehicle-type': 'Voertuigtypes',
  'setup_data-retentions': 'Data Retention',
  'add-data-retentions': 'Add Data Retention',
  'data-retentions': 'Data Retention',
  setup_carriers: 'Carriers',
  'add-carriers': 'Add Carriers',
  carriers: 'Carriers',
  'setup_concrete-classes': 'Material Compliance',
  'add-concrete-classes': 'Add Material Compliance',
  'concrete-classes': 'Material Compliance',
  'setup_forms-and-waivers': 'Forms & Waivers',
  'add-forms-and-waivers': 'Add Forms & Waivers',
  'forms-and-waivers': 'Forms & Waivers',

  companyFleet: 'Company Fleet',

  category: 'Category',
  interval: 'Interval',
  orders: 'Orders And Tickets',
  '30days': '30 Days',
  '60days': '60 Days',
  '90days': '90 Days',
  '1year': '1 Year',
  '3years': '3 Years',

  waterMandatory: 'Water Mandatory',

  qrCodeTemplate: 'QR Code Template',

  waterConfig: 'Optionele velden',

  waterProduct: 'Water',
  waterProductType: 'Type waterproduct',
  defaultWaterProduct: 'Standaard waterproduct',

  invalidTemplate: 'Ongeldige sjabloon',

  siteAddress: 'Adress losplaats',
  siteContactName: 'Naam sitecontact',
  siteContactPhoneNumber: 'Telefoonnummer sitecontact',

  'clear-signature-button': 'Handtekening wissen',

  customerCommentEnabled: 'Opmerking klant inschakelen',
  driverCommentEnabled: 'Opmerking chauffeur inschakelen',

  driverCommentMaxCharacters: 'Max Lengte',
  customerCommentMaxCharacters: 'Max Lengte',

  timeZone: 'Tijdzone',

  driverDidNotAdd: 'Werd niet toegevoegd',
  maxLineItems: 'Max aantal regelitems',
  oneLineItem: 'Moet minstens 1 regelitem hebben',

  signatureSiteContact: 'Klantnaam',
  ticketEvents: 'Ticketgebeurtenissen',
  hideEvent: 'Verberg de gebeurtenis',
  editable: 'Bewerkbaar',

  ticketDetail: `Ticket #{value}`,
  statusTimes: 'Status Tijden',

  materialAndDeliveryInfo: 'Materiaal en leveringsinformatie',

  customerRejection: 'Afwijzing klant',
  'submit-rejection-button': 'Afwijzing indienen',
  accept: 'Accepteren',
  reject: 'Afwijzen',

  signature: 'Handtekening',
  'no-signature': 'Geen handtekening',

  unFinalizePassword: 'Un-Finalize Wachtwoord',

  waterInWorkflow: 'Toevoeging van water moet verplicht ingevuld worden',
  multicountry: 'Multicountry',

  timestampAfterSequence: 'WAARSCHUWING: Tijd mag niet voor het vorige tijdstip vallen',
  timestampBeforeSequence: 'WAARSCHUWING: Tijdstip mag niet na het volgende tijdstip vallen',

  noRestQuantity: 'Geen geretourneerde hoeveelheid',

  returnedMaterialMandatory: 'Geretourneerde hoeveelheid moet verplicht ingevuld worden',

  driverCommentMaxChars: 'Length cannot exceed max of {maxLength} characters.',

  QrCodeEnabled: 'Enable QR Code',

  returnedMaterialInWorkflow: 'Enable Returned Material Step in Workflow',

  materialServiceInWorkflow: 'Enable Material/Service Step in Workflow',

  totalPumpedQuantity: 'Total Pumped Quantity',

  concrete: 'Concrete',

  materialComplianceEnabled: 'Enable Material Compliance',

  pumpStatusTimes: 'Pump Status Times',

  pollingInterval: 'Polling Interval (seconds)',

  tenSecs: 'Interval must be at least 10 seconds',

  materialCompliance: 'Material Compliance',
  consistenceValue: 'Consistence',
  consistenceIsCompliant: 'Consistence complies with BS8500-2',
  airContentValue: 'Air content',
  airContentIsCompliant: 'Air Content complies with BS8500-2',

  consistenceIsCompliantShort: 'Consistence complies',
  airContentIsCompliantShort: 'Air Content complies',
  compliance: 'Material Compliance with BS8500-2',

  family_name: 'Last Name',
  given_name: 'First Name',
  email: 'Email',
  role: 'Role',
  inviteUser: 'Invite User',
  carrier: 'Carrier',
  emailOrPhoneNumber: 'Email',
  usersEditor: 'User Editor',
  verifyPassword: 'Verify Password',
  verificationCode: 'Verification Code',
  register: 'Register',
  registerInstructions: 'Please fill out this form to finalize your registration.',
  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',
  passwordMinCharacters: 'Password must contain 8 or more characters.',
  passwordMaxCharacters: 'Password cannot contain more than 99 characters.',
  passwordLowerCase: 'Password must include 1 or more lower case letter.',
  passwordUpperCase: 'Password must include 1 or more upper case letter.',
  passwordNumber: 'Password must include 1 or more numeric character.',
  passwordSpecialCharacter: 'Password must include 1 or more special character.',
  passwordsDontMatch: "Passwords don't match!",
  success: 'Success!',
  'registration-success': 'Your registration was successful.',
  'post-registration-instructions': "You've completed the registration process. You may now log in.",
  createDate: 'Create Date',
  customerPo: 'Customer PO Number',
  specification: 'Specification',

  description: 'Description',
  classType: 'Class Type',
  minimumValue: 'Minimum Value',
  maximumValue: 'Maximum Value',

  TrackIt: 'TrackIt',
  trackIt3PContext: 'TrackIt 3P Context',
  inviteLanguage: 'Invite Language',
  truckNumber: 'Truck Number',
  adverseTemperature:
    'Ambient temperature at the time of mixing was such that we cannot guarantee that the concrete will be in the temperature ranges of 5-35oC as specified in EN 206.',
  environmentalNote:
    'This load contains an environmental agent which does not exceed 0.03% by weight of the concrete. Water containing this material for the manufacture of concrete will comply with BS EN 1008.',

  unloadingMethodEnabled: 'Enable Unloading Method',

  content: 'Content',
  WARNINGS: 'Warnings',
  TERMS: 'Terms',
  DAMAGE: 'Damage',
  ADDITIONALWAIVER: 'Additional Waiver',

  warrantyVoided: 'Material Warranty Voided',
  materialWarrantyVoidedEnabled:
    'When Water and/or Additional Materials are added show a Certification Loss Note at time of signature.',

  'setup_templates-mt': 'Sjablonen',
  'copyFrom': 'Kopieer van...',

  errors,
  themes,
};
