import common from '../common';
import errors from './errors';
import themes from './theme';
import features from './features';

export default {
  ...common,

  mobileTicket: 'Elektronisk følgeseddel',
  ...features,

  save: 'Gem',
  username: 'Brugernavn',
  password: 'Password',
  login: 'Log ind',
  required: 'Obligatorisk',

  light: 'Lys',
  dark: 'Mørk',

  rememberMe: 'Husk mig',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Fortrolighedspolitik',

  customer: 'Kunde',
  project: 'Projekt',

  isBillable: 'Kan kontaktes',
  dispatchDateTime: 'Dato',
  orderId: 'Order',
  ticketId: 'Følgeseddel',
  location: 'Lokation',
  vehicleTypeName: 'Køretøj',
  product: 'Produkt',
  salesTax: 'moms',
  totalPrice: 'Total',
  extendedPrice: 'Forøget pris',
  destination: 'Destination',
  productId: 'Produkt ID',
  statusCode: 'Status',
  vehicleTypeId: 'Køretøj ID',
  locationId: 'Lokation ID',
  customerId: 'Kunde ID',
  projectId: 'Projekt ID ',
  dispatchTime: 'Tid',

  showAll: 'Vis alt',
  hideAll: 'Gem alt',
  undoChanges: 'slet ændringer',
  resetColumns: 'Reset kolonner',

  displayRowCount: `Vis {count, plural, 
    =0 {0 rows} 
    =1 {# row} 
    other {# rows} 
  }`,

  columnFilters: 'Kolonne filter',

  visible: 'Synligt',
  hidden: 'Gemt',

  topSection: 'Øverste Sektion',

  itemDetail: 'Ordrerække detaljer',

  quantity: 'Antal',

  unloadingMethod: 'Aflæsnings type',

  time: 'Tid',
  eventStatus: 'Status',

  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',

  MTQ_text: 'Cubic Meters',
  M3_text: 'Cubic Meters',
  EA_text: 'Each',
  LTR_text: 'Liters',
  YDQ_text: 'Cubic Yards',
  GLL_text: 'Gallons',
  ACR_text: 'Acres',
  HUR_text: 'Hour',
  INH_text: 'Inches',
  FOT_text: 'Foot',
  YRD_text: 'Yard',
  MMT_text: 'Millimetre',
  CMT_text: 'Centimetre',
  MTR_text: 'Metre',
  SMI_text: 'Mile (Statute Mile)',
  KMT_text: 'Kilometre',
  INK_text: 'Square Inch',
  FTK_text: 'Square Foot',
  YDK_text: 'Square Yard',
  MIK_text: 'Square Mile',
  CMK_text: 'Square Centimetre',
  MTK_text: 'Cubic Metre',
  KMK_text: 'Square Kilometre',
  INQ_text: 'Cubic Inch',
  MMQ_text: 'Cubic Millimetre',
  FTQ_text: 'Cubic Foot',
  CMQ_text: 'Cubic Centimetre',
  OZA_text: 'Fluid Ounce',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Millilitre',
  ONZ_text: 'Ounce',
  LBR_text: 'Pound',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Tonne',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton Per Square Millimetre',
  C62_text: 'One',
  NL_text: 'Load',
  MT_text: 'Mat',
  P1_text: 'Percent',
  CEL_text: 'Degree Celsius',
  FAH_text: 'Degree Fahrenheit',
  BLL_text: 'Barrel',
  CLT_text: 'Centilitre',
  DLT_text: 'Decilitre',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sack',
  '60_text': 'Percent Weight',
  BG_text: 'Bag',
  SEC_text: 'Second',
  MIN_text: 'Minute',
  '64_text': 'Pound per square inch - Gauge',
  SP_text: 'Shelf Package',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    =1 {1 m\u00B3}
    other {# m\u00B3}
  }`,

  M3: `{value, plural,
    =0 {0 m\u00B3}
    =1 {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liters}
    =1 {1 Liter}
    other {# Liters}
  }`,

  TO: `{value, plural,
    =0 {0 Tons}
    =1 {1 Ton}
    other {# Tons}
  }`,

  ITEM: `{value, plural,
    =0 {0 Items}
    =1 {1 Item}
    other {# Items}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    =1 {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    =1 {1 Cubic Yard}
    other {# Cubic Yards}
  }`,

  GLL: `{value, plural,
    =0 {0 Gallons}
    =1 {1 Gallon}
    other {# Gallons}
  }`,

  EA: `{value, plural,
    =0 {0 Each}
    =1 {1 Each}
    other {# Each}
  }`,

  MIN: `{value, plural,
    =0 {0 Minutes}
    =1 {1 Minute}
    other {# Minutes}
  }`,

  ACR: `{value, plural,
    =0 {0 Acres}
    =1 {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    =1 {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    =1 {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    =1 {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    =1 {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    =1 {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    =1 {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    =1 {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    =1 {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    =1 {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    =1 {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    =1 {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    =1 {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    =1 {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    =1 {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    =1 {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    =1 {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    =1 {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    =1 {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    =1 {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    =1 {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    =1 {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    =1 {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    =1 {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    =1 {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    =1 {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    =1 {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    =1 {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    =1 {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    =1 {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    =1 {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    =1 {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    =1 {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    =1 {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    =1 {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    =1 {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    =1 {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
    =1 {1 Percent}
    other {# Percent}
}`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
    =1 {1 Degree Celsius}
    other {# Degrees Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
    =1 {1 Degree Fahrenheit}
    other {# Degrees Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Barrels}
    =1 {1 Barrel}
    other {# Barrels}
}`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
    =1 {1 Centilitre}
    other {# Centilitres}
}`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
    =1 {1 Decilitre}
    other {# Decilitres}
}`,
  GLI: `{value, plural,
    =0 {0 Gallons}
    =1 {1 Gallon}
    other {# Gallons}
}`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
    =1 {1 Megagram}
    other {# Megagrams}
}`,
  SA: `{value, plural,
    =0 {0 Sacks}
    =1 {1 Sack}
    other {# Sacks}
}`,
  60: `{value, plural,
    =0 {0 Percent Weight}
    =1 {1 Percent Weight}
    other {# Percent Weight}
}`,
  BG: `{value, plural,
    =0 {0 Bags}
    =1 {1 Bag}
    other {# Bags}
}`,
  SEC: `{value, plural,
    =0 {0 Seconds}
    =1 {1 Second}
    other {# Seconds}
}`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
    =1 {1 Pound per square inch - Gauge}
    other {# Pounds per square inch - Gauge}
}`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
    =1 {1 Shelf Package}
    other {# Shelf Packages}
}`,

  mixIdLabel: 'Blanding / Beskrivelse',
  orderedQtyLabel: 'Bestilt mængde',
  loadedQtyLabel: 'Læsset mængde',

  addedByCustomer: 'Tilføjet af kunde',

  PRINTED: 'Tildelt',
  LOADING_STARTED: 'Start læsning',
  LOADING_COMPLETE: 'Læsset',
  TO_JOB: 'Fra fabrik',
  ARRIVE_JOB: 'Ankomst Byggeplads',
  UNLOADING: 'Start aflæsningstid',
  END_UNLOADING: 'Slut aflæsningstid',
  LEAVE_JOB: 'Fra byggeplads',
  IN_YARD: 'I gården',
  PUMPING_STARTED: 'Start Pumpning',
  PUMPING_COMPLETE: 'Slut Pumpning',
  READY_TO_PUMP: 'Klar til at pumpe',
  FIRST_WCCONTACT: 'Tid V/C kontakt',

  plantName: 'Fabriks navn',
  plantAddress: 'Fabriks adresse',
  loadingScheduleStart: 'Planlagt læssetid',

  deliveryAddress: 'Leveringsadresse',
  phoneNumber: 'Telefonnummer',
  unloadingScheduleStart: 'Planlagt aflæsnings tidspunkt',
  agreedUnloading: 'Bestilt aflæsningstid',

  deliveryInformation: 'Leverings information',
  next: 'Næste',

  material: 'Materiale',
  loadingPoint: 'Læssested',
  unloadingPoint: 'Aflæsningssted',

  comment: 'Kommentarer',

  sapDeliveryNumber: 'Følgeseddelsnummer',
  salesOrderNumber: 'Ordernummer og orderrække',
  wcDateTime: 'Dato og Tid V/C kontakt',
  po: 'Sags nr.',
  orderedByName: 'Bestillers navn',
  orderedByPhone: 'Bestillers telefonnummer',
  resourceId: 'Køretøj ID',
  totalLoadedQty: 'Total læsset mængde inkl.dette læs',
  spacing: 'Interval, min.',
  deliveryFlow: 'Leverings flow, m3/t',
  timeOnSite: 'Bestilt aflæsningstid, min.',
  salesText: 'Salgs tekst',
  strengthClass: 'Styrkeklasse ',
  environmentExposure: 'Miljøpåvirkning',
  metExposureClasses: 'Eksponeringsklasse',
  dmax: 'Dmax',
  standardCert: 'Standard',
  workabilityTarget: 'Konsistens',
  stoneQuality: 'Stenkvalitet',
  sandQuality: 'Sandkvalitet',
  targetWC: 'Tilstræbt V/C forh.',
  controlClass: 'Kontrolklasse',
  chlorideClass: 'Chloridklasse',
  typeAndStrength1: 'Cement 1',
  typeAndStrength2: 'Cement 2',
  typeOfAddition: 'Tilsætningsmaterialer',
  typeOfAdmixture: 'Tilsætningsmiddel',
  orderedTemp: 'Bestilt temperatur',
  fibers1: 'Type og mængde af fiber 1',
  fibers2: 'Type og mængde af fiber 2',
  calculatedMaturityTime: 'Modenhedstid overskrides kl',

  mixIdentity: 'Mix Identity',
  grade: 'Grade',
  mixType: 'Mix Type',
  maxAggSize: 'Max Agg Size',
  maxAggType: 'Max Agg Type',
  cementType: 'Cement Type',
  admixtures: 'Admixtures',
  addmixDescription: 'Addmix Description',
  slump: 'Slump',
  minCementContent: 'Min Cement Content',
  maxWatCemRatio: 'Max Water/Cement Ratio',
  maxWaterToAdd: 'Max Water To Add',
  mortarMinWorkingLife: 'Mortar Min Working Life',
  code: 'Code',
  dcClass: 'dcClass',
  chloride: 'Chloride',
  signedByHanson: 'Signed By Hanson',
  warmStamp: 'Warm Stamp',
  coldStamp: 'Cold Stamp',
  containsSikatard: 'Contains Sikatard',
  containsDelvo: 'Contains Delvo',

  generalDelivery: 'Generel leverings information',
  materialInfo: 'Materiale information',

  water: 'Vand',
  materialService: 'Materiale/Service',
  returnedMaterial: 'Returbeton',

  waterAdded: 'Vand tilsat',
  concreteOnTruck: 'Mængde i køretøj',
  concreteOnTruckUom: 'Standard kapacitet køretøj',
  reason: 'Årsag',
  customerAddedWater: 'Kunden har tilsat vand',
  addWater: 'Tilsæt vand',
  addedAt: 'Tilføjet ved ',

  addMaterialService: 'Tilsæt materiale/service',
  customerAddedProduct: 'Kunden tilsat produkt',

  addReturnedMaterial: 'Tillæg Returneret beton',
  leftover: 'Restbeton',

  submitAcceptance: 'Accept',
  customerAcceptance: 'Kundens accept',
  finalize: 'Afslut',

  materialSummary: 'Summering',
  mix: 'Blanding',
  ordered: 'Bestilt',
  loaded: 'Leveringslæs',
  totalOrdered: 'Total order mængde',
  loadedOrderQuantity: 'Læsset order mængde',
  loadedOrder: 'Læsset order',

  driverComment: 'Chauffør kommentarer',
  customerComment: 'Kunde kommentarer',
  editComment: 'Ret kommentarer',
  addComment: 'Tilføj kommentar',
  additionalInfo: 'Ekstra information',
  onBoard: 'Læsset',
  driver: 'Chauffør',
  commentInstruction: 'Kommentar felt',
  signatureInstruction: 'Underskrift',
  customerSignature: 'Kundens underskrift',

  general: 'Generelt',
  status: 'Status',
  updateStatusTime: 'Opdatere status tid',
  delete: 'Slet',
  cancel: 'Annullere',
  back: 'Tilbage',
  add: 'Tilføj',
  ok: 'OK',
  clear: 'Ryd',
  reset: 'Reset ',
  done: 'Færdig',
  lastRefresh: 'Sidste opdatering',
  refreshEnabled: 'Aktivere opdatering',

  id: 'ID',
  name: 'Navn',
  invoiceable: 'Fakturerings klar',
  provideSignature: 'Underskrift',
  ticket: 'Følgeseddel',
  details: 'Detaljer',
  qrCode: 'QR',

  driverAcceptanceReason: 'Årsag',
  driverAcceptance: 'Chauffør godkendelse',

  'reason-code': 'Årsagskode',
  'add-reason-code': 'Tilføj årsagskode',

  'qr-code': 'QR Kode',
  'unloading-method': 'Aflæsningstype',
  'add-unloading-method': 'Tilføj Aflæsningstype',

  'driver-comment': 'Chauffør kommentar',
  'add-driver-comment': 'Tilføj Chauffør kommentarer',
  'driver-sellable': 'Driver Sellable',

  vehicle: 'Køretøjer',
  'add-vehicle': 'Tilføj køretøj',

  'vehicle-type': 'Køretøjs type',
  vehicleTypes: 'Køretøjs typer',
  vehicleType: 'Køretøjs type',
  'add-vehicle-type': 'Tilføj køretøjs type',
  countries: 'Lande',
  country: 'Land',

  type: 'Type',
  uom: 'Måleenhed',
  mandatory: 'Obligatorisk',

  qrTemplate: 'QR-kode',
  includeMixInfo: 'Inkluderer materialeinformation i QR-kode',
  includeTicketEvents: 'Inkluderer følgeseddelshændelser i QR-kode',

  quantityField: 'Aktivere mængde felt',
  concreteOnTruckField: 'Aktivere betonfelt på bilen',
  timeField: 'Aktivere tids felt',
  reasonField: 'Aktivere årsags felt ',

  driverInstructions: 'Chauffør instruktioner',

  waitingForTicket: 'Waiting for ticket...',

  exceedsLoadedQuantity: 'Værdien kan ikke overskride blandede mængde {loadedQuantity}',
  maxQuantity: 'Maximal mængde',
  maxQuantityError: 'Mængde kan ikke overskride defineret max mængde {maxQty}',
  maxValueError: 'Value cannot exceed defined maximum of {maxQty}',
  minValueError: 'Value cannot be less than defined minimum of {minQty}',

  notAuthorized: 'Ikke Autoriseret',

  enterTruckNumber: 'Indsæt køretøjs nummer',
  setTruckNumber: 'Angiv køretøjs nummer',

  commentList: 'Kommentarliste',
  list: 'Liste',

  gridPreferences: 'Præference grid',

  loadingUnloadingPoints: 'Læsse-/aflæsningssted',

  activity: 'Aktivitet',

  crn: 'CRN',
  update: 'Opdatering',

  CONCRETE: 'Beton',
  WATER: 'Vand',
  ADDITIVE: 'Additiver',

  'submit-signature-button': 'Acceptere',
  'cancel-signature-button': 'Annullere',

  uomCode: 'Standard måleenhed',
  'product-type': 'Produkt type',
  'add-product-type': 'Tilføj produkt type',
  'add-product': 'Tilføj produkt',
  'setup_driver-comment': 'Chauffør kommentar',
  setup_product: 'Produkter',
  'setup_product-type': 'Produkt type',
  'setup_qr-code': 'QR Kode',
  'setup_reason-code': 'Årsags koder',
  'setup_unloading-method': 'Aflæsningstype',
  'setup_entity-mt': 'Firma',
  setup_vehicle: 'Køretøj',
  'setup_vehicle-type': 'Køretøjs type',
  'setup_data-retentions': 'Data opbevaring',
  'add-data-retentions': 'Tilføj data opbevaring',
  'data-retentions': 'Data opbevaring',
  setup_carriers: 'Opsætning af udbyder',
  'add-carriers': 'Tilføj udbyder',
  carriers: 'Udbydere',
  'setup_concrete-classes': 'Material Compliance',
  'add-concrete-classes': 'Add Material Compliance',
  'concrete-classes': 'Material Compliance',
  'setup_forms-and-waivers': 'Forms & Waivers',
  'add-forms-and-waivers': 'Add Forms & Waivers',
  'forms-and-waivers': 'Forms & Waivers',

  companyFleet: 'Vognmænd',

  category: 'Kategori',
  interval: 'Interval',
  orders: 'Ordrer og følgesedler',
  '30days': '30 dage',
  '60days': '60 dage',
  '90days': '90 dage',
  '1year': '1 år',
  '3years': '3 år',

  waterMandatory: 'Vand Obligatorisk',

  qrCodeTemplate: 'QR Kode Master',

  waterConfig: 'Valgbart felt',

  waterProduct: 'Vand',
  waterProductType: 'Vandprodukt type',
  defaultWaterProduct: 'Standard vandprodukt',

  invalidTemplate: 'Ugyldig skabelon',

  siteAddress: 'Byggeplads Adresse',
  siteContactName: 'Kontaktperson Byggeplads',
  siteContactPhoneNumber: 'Kontaktperson Byggeplads Telefon',

  'clear-signature-button': 'Slet underskrift',

  customerCommentEnabled: 'Aktiver Kunde kommentar',
  driverCommentEnabled: 'Aktiver Chauffør kommentar',

  driverCommentMaxCharacters: 'Max længde',
  customerCommentMaxCharacters: 'Max længde',

  timeZone: 'Tidszone',

  driverDidNotAdd: 'Chauffør ej tilsat',
  maxLineItems: 'Max antal ordrelinjer',
  oneLineItem: 'Mindst en ordrelinje',

  signatureSiteContact: 'Kundenavn',
  ticketEvents: 'Følgeseddel hændelse',
  hideEvent: 'Skjul hændelse',
  editable: 'Redigerbar',

  ticketDetail: `Følgeseddels detaljer #{value}`,
  statusTimes: 'Status tider',

  materialAndDeliveryInfo: 'Matriale- og leverings information',

  customerRejection: 'Afvist af kunde',
  'submit-rejection-button': 'Godkend afvisning',
  accept: 'Acceptere',
  reject: 'Afvis',

  signature: 'Underskrift',
  'no-signature': 'Ingen underskrift',

  unFinalizePassword: 'Ej færdiggjort',

  waterInWorkflow: 'Enable Water Step in Workflow',
  multicountry: 'Flere Lande',

  timestampAfterSequence: 'Advarsel: Tid skal ikke kunne være før tidligere tidsstempel',
  timestampBeforeSequence: 'Advarsel: Tid skal ikke kunne være efter næste tidsstempel',

  noRestQuantity: 'Ingen returbeton',

  returnedMaterialMandatory: 'Må genbruge beton',

  driverCommentMaxChars: 'Længden af karakter kan ikke overskride {maxLength} karakter.',

  QrCodeEnabled: 'Aktivere QR kode',

  returnedMaterialInWorkflow: 'Aktivere returbeton i flow',
  materialServiceInWorkflow: 'Enable Material/Service Step in Workflow',

  totalPumpedQuantity: 'Total pumpet mængde',

  concrete: 'Beton',

  materialComplianceEnabled: 'Aktivere materiale overholdelse',

  pumpStatusTimes: 'Pumpe status tider',

  pollingInterval: 'Afstemnings interval',

  tenSecs: 'Interval skal være mindst 10 sekunder',

  materialCompliance: 'Material Compliance',
  consistenceValue: 'Consistence',
  consistenceIsCompliant: 'Consistence complies with BS8500-2',
  airContentValue: 'Air content',
  airContentIsCompliant: 'Air Content complies with BS8500-2',

  consistenceIsCompliantShort: 'Consistence complies',
  airContentIsCompliantShort: 'Air Content complies',
  compliance: 'Material Compliance with BS8500-2',

  family_name: 'Efternavn',
  given_name: 'Fornavn',
  email: 'Email',
  role: 'Rolle',
  inviteUser: 'Inviter bruger',
  carrier: 'Udbyder',
  emailOrPhoneNumber: 'Email',
  usersEditor: 'Bruger editor',
  verifyPassword: 'Bekræft password',
  verificationCode: 'Kode',
  register: 'Registrere',
  registerInstructions: 'Udfyld formularen',
  'password-requirements':
    'Password skal være på min 8 karakterer, indeholde 1 stort bogstav, 1 lille bogstav 1 nummer og et tegn',
  passwordMinCharacters: 'Password skal være på min 8 karakterer',
  passwordMaxCharacters: 'Password er for langt',
  passwordLowerCase: 'Password skal indeholde små bogstaver',
  passwordUpperCase: 'Password skal indeholde mindst 1 stort bogstav',
  passwordNumber: 'Password skal indeholde mindst et tal',
  passwordSpecialCharacter: 'Password skal indeholde mindst 1 tegn',
  passwordsDontMatch: 'Password matcher ikke',
  success: 'Det gik som smurt',
  'registration-success': 'Din oprettelse var succesfuld',
  'post-registration-instructions': 'Du er nu registreret. Du kan nu logge ind',
  createDate: 'Dato',
  customerPo: 'Rekvisitions nr.',
  specification: 'Standard',

  description: 'Description',
  classType: 'Class Type',
  minimumValue: 'Minimum Value',
  maximumValue: 'Maximum Value',

  TrackIt: 'TrackIt',
  trackIt3PContext: 'TrackIt 3P Context',
  inviteLanguage: 'Invite Language',
  truckNumber: 'Truck Number',
  adverseTemperature:
    'Ambient temperature at the time of mixing was such that we cannot guarantee that the concrete will be in the temperature ranges of 5-35oC as specified in EN 206.',
  environmentalNote:
    'This load contains an environmental agent which does not exceed 0.03% by weight of the concrete. Water containing this material for the manufacture of concrete will comply with BS EN 1008.',

  unloadingMethodEnabled: 'Enable Unloading Method',
  warrantyVoided: 'Material Warranty Voided',
  materialWarrantyVoidedEnabled:
    'When Water and/or Additional Materials are added show a Certification Loss Note at time of signature.',

  'setup_templates-mt': 'Skabeloner',
  'copyFrom': 'Kopi fra...',


  content: 'Content',
  WARNINGS: 'Warnings',
  TERMS: 'Terms',
  DAMAGE: 'Damage',
  ADDITIONALWAIVER: 'Additional Waiver',

  errors,
  themes,
};
