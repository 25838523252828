import { get } from 'lodash';
import constants from '../constants';

export class If {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }
  handle(params) {
    const { token, context } = params;
    if (params?.token?.startsWith?.(constants.IF)) {
      const newToken = token.slice(4);

      const escapedToken = newToken.replaceAll('\\,', constants.ESCAPED_COMMA_PLACEHOLDER);

      let [path, ifTrue, ifFalse] = escapedToken.split(',');

      ifTrue = ifTrue.replaceAll(constants.ESCAPED_COMMA_PLACEHOLDER, ',');
      ifFalse = ifFalse.replaceAll(constants.ESCAPED_COMMA_PLACEHOLDER, ',');

      return get(context, path) ? ifTrue : ifFalse;
    }
    return this?.next?.handle?.(params);
  }
}
