import { Localization } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTemplatesSetup } from '../../MasterDataProvider';
import processTemplate from './process';
import { suppressEmpty } from './suppressEmpty';

export const TEMPLATES = {
  MATERIAL_SUMMARY: 'materialSummary',
  MATERIAL_INFORMATION: 'materialInfo',
  GENERAL_DELIVERY_INFORMATION: 'generalDeliveryInfo',
};

export const useTemplate = ({ context, templateName, shouldSuppressEmpty = true }) => {
  const getTimeZone = Localization.useGetTimeZone();
  const intl = useIntl();
  const translateMessage = Localization.useTranslateMessage();
  const { userLocale } = Localization.useLocalizationContext();

  const templatesSetup = useTemplatesSetup();

  const templateString = React.useMemo(() => {
    if (templatesSetup?.data) {
      return get(
        templatesSetup?.data,
        [templateName, userLocale],
        templatesSetup.data[templateName]?.['en-US'] || 'No Template'
      );
    }
  }, [templateName, templatesSetup?.data, userLocale]);

  return React.useMemo(() => {
    const processedTemplate = processTemplate({
      context,
      templateString,
      util: { translateMessage, getTimeZone, intl },
    });

    return shouldSuppressEmpty ? suppressEmpty(processedTemplate) : processedTemplate;
  }, [context, getTimeZone, intl, shouldSuppressEmpty, templateString, translateMessage]);
};
