import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { TEMPLATES, useTemplate } from '../../../delivery-information/template-processor/useTemplate';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Detail = ({ data }) => {
  const template = useTemplate({ context: data, templateName: TEMPLATES.MATERIAL_SUMMARY });

  return (
    <Styled className={cn('materialDetail')}>
      <pre>{template}</pre>
    </Styled>
  );
};
