import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from '../style';
import { Core } from 'connex-cds';
import { Detail } from '../ticket-details/logistical-info/material-summary/Detail';
import { useTicketContext } from '../TicketContext';
import { materialSummary } from './detail-config';
import GeneralDeliveryInfo from './GeneralDeliveryInfo';
import MaterialInfo from './MaterialInfo';
import QRCode from './QRCode';
import { useCompanySetup } from '../MasterDataProvider';

const { Tabs } = Core;

const Styled = styled.div`
  ${style}
`;
const DeliveryInformation = () => {
  const { ticket } = useTicketContext();
  const companySetup = useCompanySetup();
  const tabConfig = React.useMemo(() => {
    const config = {
      tabs: [
        {
          stringId: 'materialSummary',
          testId: 'materialSummaryLabel',
          component: <Detail data={ticket} />,
        },
      ],
      translate: [{ getPath: 'stringId', setPath: 'name' }],
    };

    if (ticket?.customData?.ticketType !== 'PMP') {
      config.tabs.push(
        {
          stringId: 'generalDelivery',
          testId: 'generalDeliveryLabel',
          component: <GeneralDeliveryInfo data={ticket}></GeneralDeliveryInfo>,
        },
        {
          stringId: 'materialInfo',
          testId: 'materialInfoLabel',
          component: <MaterialInfo data={ticket}></MaterialInfo>,
        }
      );
    }

    if (companySetup?.data?.isQrCodeEnabled && ticket?.customData?.ticketType !== 'PMP') {
      config.tabs.push({
        stringId: 'qrCode',
        testId: 'qrCodeLabel',
        component: <QRCode />,
      });
    }
    return config;
  }, [companySetup, ticket]);

  return (
    <Styled className={cn('test-form')}>
      <div>
        <Tabs config={tabConfig} />
      </div>
    </Styled>
  );
};

export default DeliveryInformation;
