import { get } from 'lodash';
import { DateTime as LuxonDateTime } from 'luxon';
import constants from '../constants';

export class DateTime {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }

  handle(params) {
    if (params?.token?.startsWith?.(constants.DATE_TIME)) {
      const { token, context, util } = params;

      const isoDatePath = token.slice(10);
      const isoDate = get(context, isoDatePath);

      const { getTimeZone, intl } = util;

      console.log('isoDate', isoDate);

      const timeZone = getTimeZone({ date: LuxonDateTime.fromISO(isoDate) });

      const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone,
      };

      const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        timeZone,
      };

      const dt = LuxonDateTime.fromISO(isoDate);

      const formattedDate = intl.formatDate(dt.toJSDate(), dateOptions);
      const formattedTime = intl.formatTime(dt.toJSDate(), timeOptions);
      return `${formattedDate} ${formattedTime} (${timeZone})`;
    }
    return this?.next?.handle?.(params);
  }
}
