import React from 'react';
import { TemplateTab } from './TemplateTab';

const templateTabConfig = {
  tabs: [
    {
      stringId: 'materialSummary',
      testId: 'materialSummaryLabel',
      component: <TemplateTab templateName="materialSummary" />,
    },
    {
      stringId: 'generalDelivery',
      testId: 'generalDeliveryLabel',
      component: <TemplateTab templateName="generalDeliveryInfo" />,
    },
    {
      stringId: 'materialInfo',
      testId: 'materialInfoLabel',
      component: <TemplateTab templateName="materialInfo" />,
    },
  ],
  translate: [{ getPath: 'stringId', setPath: 'name' }],
};

export default templateTabConfig;
