import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Localization } from 'connex-cds';
import { useParams } from 'react-router-dom';
import { admin, masterData as masterDataApi, masterData } from '../../../../api';

export const useCreateItem = () => {
  const { entityRef, typeId: itemType } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, itemType];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.createSetupData({ entityRef, itemType, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useUpdateItem = () => {
  const { entityRef, typeId: itemType } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, itemType];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.updateSetupData({ entityRef, itemType, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useCreateSetupItem = () => {
  const { entityRef, typeId } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, typeId];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.createMasterData({ entityRef, typeId, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useUpdateSetupItem = () => {
  const { entityRef, typeId } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, typeId];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.updateMasterData({ entityRef, typeId, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useSetupByTypeId = () => {
  const { entityRef, typeId } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, typeId],
    queryFn: () => masterData.getMasterData({ entityRef, typeId }),
  });
};

export const useCompanySetup = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['setup', entityRef, 'entity-mt'],
    queryFn: () => masterData.getMasterData({ entityRef, typeId: 'entity-mt' }),
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query?.data?.[0],
  };
};

export const useUnloadingMethodSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'unloading-method'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'unloading-method' }),
    staleTime: Infinity,
  });
};

export const useProductSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'product'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'product' }),
    staleTime: Infinity,
  });
};

export const useProductTypeSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'product-type'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'product-type' }),
    staleTime: Infinity,
  });
};

export const useUomSetup = () => {
  return useQuery({
    queryKey: ['setup', 'uom'],
    queryFn: () => masterDataApi.getMasterData({ typeId: 'uom' }),
    staleTime: Infinity,
  });
};

export const useDriverCommentSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'driver-comment'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'driver-comment' }),
    staleTime: Infinity,
  });
};

export const useVehicleSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'vehicle'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'vehicle' }),
    staleTime: Infinity,
  });
};

export const useVehicleTypeSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'vehicle-type'],
    queryFn: () => masterData.getMasterData({ entityRef, typeId: 'vehicle-type' }),
    staleTime: Infinity,
  });
};

export const useReasonCodeSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'reason-code'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'reason-code' }),
    staleTime: Infinity,
  });
};

export const useCompany = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['admin', 'entities', entityRef],
    queryFn: () => admin.getEntity({ entityRef }),
    staleTime: Infinity,
  });
};

export const useDataRetentionSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'data-retentions'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, itemType: 'data-retentions' }),
    staleTime: Infinity,
  });
};

export const useCarriersSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'carriers'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, itemType: 'carriers' }),
    staleTime: Infinity,
  });
};

export const useMaterialComplianceSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'concrete-classes'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'concrete-classes' }),
    staleTime: Infinity,
  });
};

export const useFormsAndWaiversSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'forms-and-waivers'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'forms-and-waivers' }),
    staleTime: Infinity,
  });
};

export const useTemplatesSetup = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['setup', entityRef, 'templates-mt'],
    queryFn: () => masterData.getMasterData({ entityRef, typeId: 'templates-mt' }),
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query?.data?.[0],
  };
};
