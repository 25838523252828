import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { TEMPLATES, useTemplate } from './template-processor/useTemplate';

const Styled = styled.div`
  padding-top: 0;
`;

const GeneralDeliveryInfo = ({ data }) => {
  const template = useTemplate({ context: data, templateName: TEMPLATES.GENERAL_DELIVERY_INFORMATION });

  return (
    <Styled className={cn('test-form')}>
      <pre>{template}</pre>
    </Styled>
  );
};

export default GeneralDeliveryInfo;
