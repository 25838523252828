import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from '../style';
import { TEMPLATES, useTemplate } from './template-processor/useTemplate';

const Styled = styled.div`
  ${style}
`;

const MaterialInfo = ({ data }) => {
  const template = useTemplate({ context: data, templateName: TEMPLATES.MATERIAL_INFORMATION });

  return (
    <Styled className={cn('test-form')}>
      <pre>{template}</pre>
    </Styled>
  );
};

export default MaterialInfo;
