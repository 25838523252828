export default {
  languages: {
    da: 'Dansk',
    de: 'Deutsch',
    'en-US': 'English (US)',
    'en-GB': 'English (Great Britain)',
    'es-CO': 'Español (Columbia)',
    'fr-FR': 'Français',
    'nl-NL': 'Nederlands',
    'no-NO': 'Norsk',
    'sv-SE': 'Svenska',
  },
};
