import React from 'react';
import { processToken } from './handlers';

const getTokens = template => {
  const found = [];
  const rxp = /{([^}]+)}/g;
  let curMatch;

  while ((curMatch = rxp.exec(template))) {
    found.push(curMatch[1]);
  }

  return found;
};

const processTemplate = ({ context, templateString, util }) => {
  const tokens = getTokens(templateString);

  tokens.forEach(token => {
    const value = processToken({ token, context, util });

    const pattern = `{${token.replaceAll('\\,', '\\\\,')}}`;
    const regex = new RegExp(pattern, 'g');

    templateString = templateString.replace(regex, `${value}`);
  });

  return templateString;
};

export default processTemplate;
