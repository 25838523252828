import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup, useVehicleSetup, useVehicleTypeSetup } from '../../../MasterDataProvider';
import Comments from '../../../ticket-details/comments/Comments';
import DriverInstructions from '../../../ticket-details/driver-instructions/DriverInstructions';
import { LoadingUnloadingPoints } from '../../../ticket-details/logistical-info/loading-unloading-points/LoadingUnloadingPoints';
import { MaterialSummary } from '../../../ticket-details/logistical-info/material-summary/MaterialSummary';
import StatusTimes from '../../../ticket-details/logistical-info/StatusTimes';
import { Tickets } from '../../../ticket-details/ticket-list/Tickets';
import { useTicketContext } from '../../../TicketContext';
import UnloadingMethodForm from '../../../UnloadingMethodForm';
import { find } from 'lodash';

const Styled = styled.div`
  padding: 5px;
  & > * {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

export const TicketDetailMobile = props => {
  const companySetup = useCompanySetup();
  const { ticket } = useTicketContext();
  const { values } = Form.useFormContext();

  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return find(vehicleTypeSetup.data, { id: targetVehicle?.vehicleTypes });
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  const unloadingMethodEnabled = React.useMemo(() => {
    if (!companySetup?.data?.isUnloadingMethodEnabled) {
      return false;
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isUnloadingMethodEnabled !== false &&
        vehicleTypeData?.isUnloadingMethodEnabled !== true
      ) {
        return false;
      }
    }
    return ticket?.customData?.ticketType !== 'PMP';
  }, [companySetup?.data?.isUnloadingMethodEnabled, ticket?.customData?.ticketType, vehicleTypeData]);

  return (
    <Styled className={cn('ticket-detail-mobile')}>
      {ticket?.customData?.ticketType === 'PMP' && <Tickets />}
      <MaterialSummary />
      <LoadingUnloadingPoints data={values} />
      {companySetup?.data?.isDriverCommentEnabled && (
        <Layout.Container style={{ padding: '15px' }}>
          <Comments />
        </Layout.Container>
      )}
      {unloadingMethodEnabled && (
        <Layout.Container style={{ padding: '10px', paddingBottom: '5px' }}>
          <UnloadingMethodForm />
        </Layout.Container>
      )}
      <Layout.Container style={{ padding: '10px', flex: '1' }}>
        <DriverInstructions data={values} />
      </Layout.Container>
      <StatusTimes />
    </Styled>
  );
};
